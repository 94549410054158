import { template as template_dc373ff6727345b593c4c8b4ea948457 } from "@ember/template-compiler";
const WelcomeHeader = template_dc373ff6727345b593c4c8b4ea948457(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
