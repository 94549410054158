import { template as template_91b83741f5034a518104fbf4a5b90abd } from "@ember/template-compiler";
const SidebarSectionMessage = template_91b83741f5034a518104fbf4a5b90abd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
