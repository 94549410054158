import { template as template_91a27d4c9dfe4e51971c1bd08c7e06e6 } from "@ember/template-compiler";
const Header = template_91a27d4c9dfe4e51971c1bd08c7e06e6(`
  <tr>
    {{#each @columns as |entry|}}
      <entry.value.header
        @sortable={{@sortable}}
        @activeOrder={{@order}}
        @changeSort={{@changeSort}}
        @ascending={{@ascending}}
        @category={{@category}}
        @name={{@listTitle}}
        @bulkSelectEnabled={{@bulkSelectEnabled}}
        @showBulkToggle={{@toggleInTitle}}
        @canBulkSelect={{@canBulkSelect}}
        @canDoBulkActions={{@canDoBulkActions}}
        @showTopicsAndRepliesToggle={{@showTopicsAndRepliesToggle}}
        @newListSubset={{@newListSubset}}
        @newRepliesCount={{@newRepliesCount}}
        @newTopicsCount={{@newTopicsCount}}
        @bulkSelectHelper={{@bulkSelectHelper}}
        @changeNewListSubset={{@changeNewListSubset}}
      />
    {{/each}}
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Header;
